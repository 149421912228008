import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "color"
    }}>{`Color`}</h2>
    <p><em parentName="p">{`Note: The UI Shell does not currently use the Carbon theme tokens; theming options for the shell will be available in the future. All color used in the UI Shell is from the `}<a parentName="em" {...{
          "href": "https://www.ibm.com/design/language/elements/color#specifications"
        }}>{`IBM Design Langauge palette`}</a>{`.`}</em></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 100`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 80`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__name`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-trigger`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__nav::before`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 80`}</td>
        </tr>
      </tbody>
    </table>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "17.857142857142858%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "ui shell header",
        "title": "ui shell header",
        "src": "/static/fbfaf744a7c43145ecabd804d1a1c01f/fb070/header-style-1.png",
        "srcSet": ["/static/fbfaf744a7c43145ecabd804d1a1c01f/d6747/header-style-1.png 288w", "/static/fbfaf744a7c43145ecabd804d1a1c01f/09548/header-style-1.png 576w", "/static/fbfaf744a7c43145ecabd804d1a1c01f/fb070/header-style-1.png 1152w", "/static/fbfaf744a7c43145ecabd804d1a1c01f/fb104/header-style-1.png 1728w", "/static/fbfaf744a7c43145ecabd804d1a1c01f/8fefe/header-style-1.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "menu-trigger"
    }}>{`Menu-trigger`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-trigger`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-trigger:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 100-hover`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-trigger:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`White`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-trigger:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 80`}</td>
        </tr>
      </tbody>
    </table>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "36.684782608695656%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Menu trigger interactive states",
        "title": "Menu trigger interactive states",
        "src": "/static/d2bb66a6bce0c988d7ec9a49ff9c2047/fb070/header-style-5.png",
        "srcSet": ["/static/d2bb66a6bce0c988d7ec9a49ff9c2047/d6747/header-style-5.png 288w", "/static/d2bb66a6bce0c988d7ec9a49ff9c2047/09548/header-style-5.png 576w", "/static/d2bb66a6bce0c988d7ec9a49ff9c2047/fb070/header-style-5.png 1152w", "/static/d2bb66a6bce0c988d7ec9a49ff9c2047/c3e47/header-style-5.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "menu-item"
    }}>{`Menu-item`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-item`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 30`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-item`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`svg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 30`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-item:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 100-hover`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-item:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-item:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`svg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-item:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`White`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-item:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 80`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-item:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-item:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`svg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 10`}</td>
        </tr>
      </tbody>
    </table>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "82.88043478260869%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Menu item interactive states",
        "title": "Menu item interactive states",
        "src": "/static/f75c12203737ddd1d5a6b3bfd53d0fad/fb070/header-style-6.png",
        "srcSet": ["/static/f75c12203737ddd1d5a6b3bfd53d0fad/d6747/header-style-6.png 288w", "/static/f75c12203737ddd1d5a6b3bfd53d0fad/09548/header-style-6.png 576w", "/static/f75c12203737ddd1d5a6b3bfd53d0fad/fb070/header-style-6.png 1152w", "/static/f75c12203737ddd1d5a6b3bfd53d0fad/c3e47/header-style-6.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "sub-menu"
    }}>{`Sub-menu`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__submenu`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 90`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__submenu`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 30`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__submenu:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 90-hover`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__submenu:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__submenu:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`White`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__submenu:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 70`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__submenu:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 10`}</td>
        </tr>
      </tbody>
    </table>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "40.714285714285715%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Sub-menu interactive states",
        "title": "Sub-menu interactive states",
        "src": "/static/f5a5719ae8f2987f65c4b336d16a62af/fb070/header-style-7.png",
        "srcSet": ["/static/f5a5719ae8f2987f65c4b336d16a62af/d6747/header-style-7.png 288w", "/static/f5a5719ae8f2987f65c4b336d16a62af/09548/header-style-7.png 576w", "/static/f5a5719ae8f2987f65c4b336d16a62af/fb070/header-style-7.png 1152w", "/static/f5a5719ae8f2987f65c4b336d16a62af/fb104/header-style-7.png 1728w", "/static/f5a5719ae8f2987f65c4b336d16a62af/8fefe/header-style-7.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "action"
    }}>{`Action`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__action`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 30`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__action:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 100-hover`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__action:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__action:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`White`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__action:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 80`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__action:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 10`}</td>
        </tr>
      </tbody>
    </table>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "82.88043478260869%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Header action interactive states",
        "title": "Header action interactive states",
        "src": "/static/2a7b4127b3fa333503e5fa42daa3c6cf/fb070/header-style-8.png",
        "srcSet": ["/static/2a7b4127b3fa333503e5fa42daa3c6cf/d6747/header-style-8.png 288w", "/static/2a7b4127b3fa333503e5fa42daa3c6cf/09548/header-style-8.png 576w", "/static/2a7b4127b3fa333503e5fa42daa3c6cf/fb070/header-style-8.png 1152w", "/static/2a7b4127b3fa333503e5fa42daa3c6cf/c3e47/header-style-8.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h2 {...{
      "id": "typography"
    }}>{`Typography`}</h2>
    <p>{`Menu labels and text should be set in sentence case.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-size (px/rem)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-weight`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__name`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$body-short-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__name--prefix`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SemiBold / 600`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$heading-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-item`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$body-short-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    {
      /*
      ### Text overflow
      [Guidance for text overflow and wrapping for platform name or labels]
      */
    }
    <h2 {...{
      "id": "structure"
    }}>{`Structure`}</h2>
    <p>{`The header should span the full width of the browser window. The header can either stay sticky to the top of the browser or scroll away.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px/rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48 / 3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-trigger`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height, width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48 / 3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__name`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__name`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32 / 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-07`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__nav`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-item`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left, padding right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-arrow`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8 / 0.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__submenu`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left, padding right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__action`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height, width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48 / 3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
      </tbody>
    </table>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "38.57142857142858%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "ui shell header spec",
        "title": "ui shell header spec",
        "src": "/static/fe2b407220eada8ffb2e1c8522b81e0c/fb070/header-style-2.png",
        "srcSet": ["/static/fe2b407220eada8ffb2e1c8522b81e0c/d6747/header-style-2.png 288w", "/static/fe2b407220eada8ffb2e1c8522b81e0c/09548/header-style-2.png 576w", "/static/fe2b407220eada8ffb2e1c8522b81e0c/fb070/header-style-2.png 1152w", "/static/fe2b407220eada8ffb2e1c8522b81e0c/fb104/header-style-2.png 1728w", "/static/fe2b407220eada8ffb2e1c8522b81e0c/8fefe/header-style-2.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "38.57142857142858%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "ui shell header submenu spec",
        "title": "ui shell header submenu spec",
        "src": "/static/567933379383cf030741e5c59d43144d/fb070/header-style-3.png",
        "srcSet": ["/static/567933379383cf030741e5c59d43144d/d6747/header-style-3.png 288w", "/static/567933379383cf030741e5c59d43144d/09548/header-style-3.png 576w", "/static/567933379383cf030741e5c59d43144d/fb070/header-style-3.png 1152w", "/static/567933379383cf030741e5c59d43144d/fb104/header-style-3.png 1728w", "/static/567933379383cf030741e5c59d43144d/8fefe/header-style-3.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "responsive-behavior"
    }}>{`Responsive behavior`}</h3>
    <p>{`In smaller broswer windows, `}<inlineCode parentName="p">{`menu items`}</inlineCode>{` in the header should collapse into the left side nav menu. Items that were once in the header should stack at the top of the side nav panel if other items were already present in the panel.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "60.44642857142857%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "responsive behavior example",
        "title": "responsive behavior example",
        "src": "/static/df01f93f1051b5c4edb7e40d99f28ffa/fb070/header-style-4.png",
        "srcSet": ["/static/df01f93f1051b5c4edb7e40d99f28ffa/d6747/header-style-4.png 288w", "/static/df01f93f1051b5c4edb7e40d99f28ffa/09548/header-style-4.png 576w", "/static/df01f93f1051b5c4edb7e40d99f28ffa/fb070/header-style-4.png 1152w", "/static/df01f93f1051b5c4edb7e40d99f28ffa/fb104/header-style-4.png 1728w", "/static/df01f93f1051b5c4edb7e40d99f28ffa/8fefe/header-style-4.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      